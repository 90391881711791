<template>
  <div class="box">
    <div class="headline">检查流程</div>
    <div class="second_line">5.2体系落地检查</div>
    <span class="text">
      点击【首页】-【体系落地检查】，进入到文件检查列表，如下图。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/19.png')"
      :preview-src-list="[require('../../assets/Bankimg/19.png')]"
    >
    </el-image>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/20.png')"
      :preview-src-list="[require('../../assets/Bankimg/20.png')]"
    >
    </el-image>
    <span class="text">
      图中列表显示的就是所有体系落地检查的年度规划。
      新增体系落地检查则要先新增年度规划，输入对应的信息，待该规划批准过后，
      可以双击该列表进去体系落地检查页面，进行计划的新增，如下图：
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/21.png')"
      :preview-src-list="[require('../../assets/Bankimg/21.png')]"
    >
    </el-image>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/22.png')"
      :preview-src-list="[require('../../assets/Bankimg/22.png')]"
    >
    </el-image>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/23.png')"
      :preview-src-list="[require('../../assets/Bankimg/23.png')]"
    >
    </el-image>
    <span class="text">
      新做一次体系落地检查。 点击“抽取题目”，弹窗如下：
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/24.png')"
      :preview-src-list="[require('../../assets/Bankimg/24.png')]"
    >
    </el-image>
    <span class="text">
      弹出该岗位下的职能所对应的题库数量信息，再抽取想要抽取的题目数量，点击“确定抽题”完成检查与对应检查详情的添加，如果想要只添加检查，不抽题，则可以抽取题目的数量为“0”。
      添加完体系落地检查后可直接进入检查详情界面，也可通过双击检查列表中的某一个检查进入，界面如下图，该界面可对未抽题的检查继续抽题，也可以点击“新增提问”，在弹出窗填写《问题描述》，再点击“确定”完成对自定义问题的添加。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/25.png')"
      :preview-src-list="[require('../../assets/Bankimg/25.png')]"
    >
    </el-image>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/26.png')"
      :preview-src-list="[require('../../assets/Bankimg/26.png')]"
    >
    </el-image>
    <span class="text">
      检查详情界面可判断该检查项是否符合要求，再上传相应的图片，最后双击“审核意见”单元格并输入《审核意见》，完成对该检查项的审核，完成所有检查项的审核后再点击“完成检查”完成对该文件检查计划的检查，如果选择了不符合的项，则会产生不符合跟踪项。点符合就会直接通过，否则会弹出如下图，输入《审核意见》，上传图片，再点击“确认”即可。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/27.png')"
      :preview-src-list="[require('../../assets/Bankimg/27.png')]"
    >
    </el-image>
    <span class="text"> 点击“打印检查表”弹出如下图，根据需要可自行打印。 </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/28.png')"
      :preview-src-list="[require('../../assets/Bankimg/28.png')]"
    >
    </el-image
    ><el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/29.png')"
      :preview-src-list="[require('../../assets/Bankimg/29.png')]"
    >
    </el-image>
    <span class="text">
      完成审核后会产生对应跟踪项的数据，顺序依次为【发出报告】-【提交措施】-【提交验证】-【确定验证】-【已通过验证】如下图：
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/30.png')"
      :preview-src-list="[require('../../assets/Bankimg/30.png')]"
    >
    </el-image>
    <span class="text">
      可以点击某一行发出报告，也可以点击全部发出报告来一次性发出所有报告
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/31.png')"
      :preview-src-list="[require('../../assets/Bankimg/31.png')]"
    >
    </el-image>
    <span class="text">
      提交措施，输入措施，选择时间点击确定即可，然后直接提交验证，再确定验证时，可选择是否通过，不通过则会重新产生一条跟踪数据。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/32.png')"
      :preview-src-list="[require('../../assets/Bankimg/32.png')]"
    >
    </el-image>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/33.png')"
      :preview-src-list="[require('../../assets/Bankimg/33.png')]"
    >
    </el-image>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      srcList: "require('../../assets/Bankimg/1.jpg')",
    };
  },
};
</script>
    
    <style  lang="less" scoped>
.box {
  padding-left: 40px;
  .headline {
    font-size: 28px;
    color: #1f2d3d;
    padding-top: 28px;
    line-height: 45px;
    padding-left: 0px ;
  }
  .second_line {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 0 12px;
  }
  .text {
    color: #141e31;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  img {
  }
}
</style>